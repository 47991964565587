export const cart_action = {
    addToCart: (data)=> {
        return{
            type: 'ADD_TO_CART',
            payload: data
        }
    },
    removeFromCart: (data)=> {
        return{
            type: 'REMOVE_FROM_CART',
            payload: data
        }
    },
    emptyCart: (data)=> {
        return{
            type: 'EMPTY_CART',
            payload: data
        }
    },

}

