const menuReducer = function(state = {}, action ) {
    
    switch (action.type) {
        case 'STORE_RESTAURANT_MENU':
            let _state = {...state};
            _state = action.payload;
            return _state;
        case 'SET_MENU_DETAIL':
            return action.payload
                
        default:
            return state
    }
}


export default menuReducer;