import environmentHelper from './helpers/environmentHelper';
import requestHelper from './helpers/requestHelper';


class Bill_Cls{

    fetchBill(restaurant_id, order_session_id){
        return requestHelper.requestWithAuth({
            url: environmentHelper.getUmsURL() + "/get_food_order_session_bill/",
            method:'POST',
            body:{restaurant_id,order_session_id}
        });
    }
    
    updatePaymentStatus(data){
        return requestHelper.requestWithAuth({
            url: environmentHelper.getUmsURL() + "/update_bill_status_for_session/",
            method:'POST',
            body: data
        });
    }
    
    checkBillStatus(restaurant_id, order_session_id){
        return requestHelper.requestWithAuth({
            url: environmentHelper.getUmsURL() + "/bill_status_for_session/",
            method:'POST',
            body: {restaurant_id, order_session_id}
        });
    }
}

export default new Bill_Cls();
