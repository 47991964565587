
class RequestHelper{
    
    initalize(){
        console.log('Initializing RequestHelper');
        this.auth_token = null;
    }
    
    requestWithAuth(config){
        //config.credentials = 'include';
        config.withCredentials =  true;
        
        if(!config.headers){
            config.headers = {};
        }
        config.headers['Authorization'] = `Token ${this.auth_token}`;
        config.headers['Content-Type'] =  'application/json';
        return this.request(config);
    }
    
    request(config){
        let _this = this;
        config.method = config.method || 'GET';
        config.body = JSON.stringify(config.body) || config.body;
        
        return new Promise(function(resolve, reject) {
            fetch(config.url, config).then(response => {
                if (!response.ok){
                    _this.handleNetworkError(response)
                    throw new Error('Network response issue');
                }
                return response.json();
                
            }).then((_response) => {
                if( _response.status === 'success' ){
                    resolve(_response.data);
                }else{
                    reject(_response.data);
                }
            }).catch(err => {  
                reject(err.message);  
            });
        });
    }
    
    requestEnvConfig(config){
        let _this = this;
        
        return new Promise(function(resolve, reject) {
            fetch(config.url, config).then(response => {
                if (!response.ok){
                    throw new Error('Network response issue');
                }
                return response.json();
            }).then((_response) => {
                resolve(_response);
            }).catch(err => {  
                reject(err.message);  
            });
        });
    }
    
    handleNetworkError(response){
        console.error('Status: ' + response.status + '\nStatusText: ' + response.statusText);
    }

}

export default new RequestHelper();