import  moment  from 'moment-timezone';

class timeHelper{
    constructor() {
        //https://github.com/moment/moment-timezone/blob/develop/data/packed/latest.json
        //moment.tz.setDefault("Europe/Rome");
        //moment.tz.setDefault("America/Chicago");
    }
    
    setTimeZone(timezone){
        moment.tz.setDefault(timezone);
    }
    
    getCurrentTime(format){
        if(!format) format = "HH:mm:ss";
        return moment().format(format);
    }
    
    formatTime(timeStr){
        let t = timeStr.split('T')[1];
        t = t.substring(0, 8);
        let h = parseInt(t.split(':')[0]);
        if(h >= 12){
            t = t + ' PM'; 
        }else{
            t = t + ' AM'; 
        }
        return t;
    }
    
    bookingAllowedTime(mealTime, timeSlots, food_service, booking_for_date){
        let currentTime = parseInt(this.getCurrentTime("HHmm"));
        let output = {bookingAllowed: true, bookingWarningMsg: ""};
        
        if(food_service === 'ADVANCE_BOOKING'){
            if (this.todayDateStr() !== booking_for_date){
                return output;
            }
        }
        
        function formatTimeSlot(time){
            return time.slice(0, 2) + ":" + time.slice(2);
        };
        
        if(timeSlots && timeSlots[mealTime]){
            let timeSlot = timeSlots[mealTime].split('-');
            if(currentTime < timeSlot[0] || currentTime >= timeSlot[1]){
                output.bookingAllowed = false;
                output.bookingWarningMsg = `${mealTime} booking is allowed during hour: ${formatTimeSlot(timeSlot[0]) + '-' + formatTimeSlot(timeSlot[1])}`;
            }
        }
        return output;
    }
    
    todayDateStr(format){
        if(!format){//Default is ISO: The International Standard format
            format = "YYYY-MM-DD"
        }
        return moment().format(format);
    }
    
    dateToString(d, format){
        if(!format){
            format = "YYYY-MM-DD"
        }
        //return moment(date).format(format);
        
        let dd = d.getDate();
        dd = (dd < 10) ? '0'+dd : dd;
        
        let mm = d.getMonth() + 1;
        mm = (mm < 10) ? '0'+mm : mm;
        
        let yyyy = d.getFullYear();
        
        return yyyy + '-' + mm + '-' + dd;
    }

    todayDate(){
        return moment().format("YYYY-MM-DD");
    }
    tomorrowDate(){
        return moment().add(1,'days').format("YYYY-MM-DD");
    }
    
    // console.log("mqs d2 = ", moment().format());
    // console.log("mqs d3 = ", moment().format("YYYY/MM/DD"));
    // console.log("mqs d4 = ", dt);
    
}

export default new timeHelper();