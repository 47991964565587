export const user_input_action = {
    storeDineType: (data)=> {
        return{
            type: 'STORE_DINE_TYPE',
            payload: data
        }
    },
    setLanguage: (data)=> {
        return{
            type: 'SET_LANGUAGE',
            payload: data
        }
    },
    setSelectedMenuCat: (data)=> {
        return{
            type: 'SET_SELECTED_MENU_CAT',
            payload: data
        }
    },
    setUrlParams: (data)=> {
        return{
            type: 'SAVE_URL_PARAM',
            payload: data
        }
    },
    storeInstructions: (data)=> {
        return {
            type: 'STORE_INSTRUCTIONS',
            payload: data
        }
    },
    storeDeliveryAddress: (data)=> {
        return {
            type: 'STORE_DELIVERY_ADDRESS',
            payload: data
        }
    },
}


export function setAllowedBookingTime(data) {
    return{
        type: 'SET_ALLOWED_BOOKING_TIME',
        payload: data
    }
}
