export const user_action = {
    storeAuthStatus: (data)=> {
        return{
            type: 'STORE_AUTH_STATUS',
            payload: data
        }
    },
    storeUserPhone: (data)=> {
        return{
            type: 'STORE_USER_PHONE',
            payload: data
        }
    },
    storePendingBillStatus: (data)=> {
        return{
            type: 'STORE_PENDING_BILL_STATUS',
            payload: data
        }
    },
    storeTotalBill: (data)=> {
        return{
            type: 'STORE_TOTAL_BILL',
            payload: data
        }
    },
    storeOrderSessionId: (data)=> {
        return{
            type: 'STORE_ORDER_SESSION_ID',
            payload: data
        }
    }
}
