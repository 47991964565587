import React, {Component} from 'react';
import {Modal, Button, Container, Row, Col} from 'react-bootstrap';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {user_action} from '../../actions/user';
import { withTranslation } from 'react-i18next';

import Account from '../../revoex/Account';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';


class LoginModal extends Component{
    constructor(props) {
        super(props);
        this.OTP_LENGTH = 4;
        this.state = {
            phone: "",
            otp: "",
            otp_sent: false,
            processing: false,
        };
    }

    sendOtp(){
        let {phone} = this.state;
        if(! /^[1-9]{1}\d{11}/.test(phone)){
            alert('Invalid Number');
            return;
        }

        this.setState({processing: true});
        Account.requestForOTP(phone).then(res => {
            this.setState({otp_sent: true, processing: false});
        }).catch(err => {
             console.error(err);
             this.setState({processing: false});
        });

    }

    verifyOtp(){
        let {phone, otp} = this.state;
        let otpRegEx = new RegExp(`^\\d{${this.OTP_LENGTH}}$`);

        if(! otpRegEx.test(otp)){
            alert('Invalid OTP');
            return;
        }

        this.setState({processing: true});
        Account.verifyOTP(phone, otp).then((res) => {
            //alert.show(res);
            this.props.storeUserPhone(phone);
            this.props.storeTotalBill(0);
            this.setState({processing: false});
            this.props.store_auth_status(true);
        }).catch(err => {
             console.error(err);
             this.setState({processing: false});
        });
    }

    renderOtpButton(){
        let { t } = this.props;
        if(!this.state.otp_sent){
            if(this.state.processing){
                return (
                    <Button className="btn" onClick={()=>this.sendOtp()} disabled>
                        <span className="spinner-border spinner-border-sm" style={{marginBottom:2,marginRight:10}}></span>
                        {t('btn.sending')}
                    </Button>
                );
            }else{
                return (
                    <Button className="btn" onClick={()=>this.sendOtp()}>
                        <span>{t('btn.sendotp')}</span>
                    </Button>
                );
            }
        }else {
            if(this.state.processing){
                return (<Button className="btn" onClick={()=>this.verifyOtp()} disabled>
                    <span className="spinner-border spinner-border-sm" style={{marginBottom:2,marginRight:10}}></span>
                    {t('btn.verifying')}
                </Button>);
            }else{
                return (<Button className="btn" onClick={()=>this.verifyOtp()}>
                    <span>{t('btn.verifyotp')}</span>
                </Button>);
            }
        }
    }

    render(){
        let { t } = this.props;
        return(
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t('lbl.login_modal_label')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Container>

                    <Row>
                      <Col>
                        <div className="form-group">
                            <div style={{display:"flex",alignItems:"center"}}>
                                <span style={{fontSize:16,marginRight:5}}>{t('lbl.phone')}</span>
                                <PhoneInput inputStyle={{width:'inherit'}} country={'in'} onlyCountries={['in']} countryCodeEditable={false} masks={{in:".........."}} placeholder="phone" searchPlaceholder="phone" value={this.state.phone} onChange={phone => this.setState({phone:phone})}/>
                            </div>
                        </div>
                        {(this.state.otp_sent) &&
                        <div className="form-group">
                            <div style={{display:"flex"}}>
                                <span style={{fontSize:16,marginRight:5}}>OTP</span>
                                <div style={{width:'100%'}}>
                                    <OtpInput containerStyle={{justifyContent:"center"}} value={this.state.otp} inputStyle={{width:35,height:35,marginLeft:10,marginRight:10,fontSize:14,borderRadius:2,border:"1px solid #ced4da"}} focusStyle={{outline:"none",boxShadow:"0px 0px 0px 1px rgb(173, 214, 246)"}} separator={<span>-</span>} onChange={otp => this.setState({otp:otp})} numInputs={4} isInputNum={true}/>
                                </div>
                            </div>
                        </div>
                        }
                      </Col>
                    </Row>

                  </Container>
              </Modal.Body>
              <Modal.Footer>
                { this.renderOtpButton() }
              </Modal.Footer>
            </Modal>
        )
    }
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        store_auth_status: user_action.storeAuthStatus,
        storeUserPhone: user_action.storeUserPhone,
        storeTotalBill: user_action.storeTotalBill,
    }, dispatch)
}

export default withTranslation()(connect( null, matchDispatchToProps)(LoginModal));
