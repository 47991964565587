import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { browserHistory} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {cart_action} from '../actions/cart';
import {user_action} from '../actions/user';
import {user_input_action} from '../actions/user_input';
import { withTranslation } from 'react-i18next';

import HeaderNavbar from './components/HeaderNavbar';
import LoginModal from './modals/LoginModal';
import FoodOrder from '../revoex/FoodOrder_Cls';
import timeHelper from '../revoex/helpers/timeHelper';
import Common from '../revoex/util/Common';
import AlertModal from './modals/AlertModal';

class Cart extends Component{

    constructor(props) {
        super(props);
        this.currency = this.props.restaurant.currency_symbol;
        this.totalBill = 0;
        this.state={
            instruction: "",
            showLoginModal: false,
            placingOrder: false,
            deliveryAddress:"",
                alertType: null,
        }
    }

    placeFoodOrder = () => {
        let _this = this;
        let {userInputs, restaurant, user, cart} = this.props;

        this.setState({placingOrder: true});
        FoodOrder.placeOrder(userInputs, restaurant, user, cart).then( data => {
            _this.props.emptyCart();
            if(user.order_session_id && (user.order_session_id !== data.order_session_id)){
                console.error("order_session_id is expected to be same as earlier: " + user.order_session_id);
            }
            _this.props.storePendingBillStatus(true);
            _this.props.storeOrderSessionId(data.order_session_id);
            _this.setState({alertType: "success", placingOrder: false});
        }).catch( err => {
            _this.setState({alertType: "error", placingOrder: false});
            console.error("Error in Order Placing", err);
        });

    }

    renderCartDetail(){
        let _this = this;
        let output = [];
        let {item_list} = this.props.menu;

        Object.values(this.props.cart).map(item => {
            let cartItem = item_list[item.id];
            let qty;

            if(item.custom_quantity){
                Object.keys(item.custom_quantity).map(cq_id => {
                    qty = item.custom_quantity[cq_id]['quantity'];
                    let cq_title = cartItem.title + "(" + cartItem.custom_quantity[cq_id]['title'] + ")";
                    let cq_price = cartItem.custom_quantity[cq_id]['price'];

                    output.push(
                        <div style={{padding:10, display:"flex", alignItems:"center"}} className="row cartItemRow" key={"cartItemRow_"+item.id+"_"+cq_id}>
                            <div className="col-md-1 col-1">
                                {qty}
                            </div>
                            <div style={{display:"flex"}} className="col-md-8 col-8">
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <span style={{fontSize: "14px"}}>{cq_title}</span>
                                    <p className=" text-muted small">{cartItem.description}</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-3">
                                <strong id="summary" className="right">{_this.currency + " " + qty*cq_price}</strong>
                            </div>
                        </div>
                   )
                });
            }else{
                qty = item.quantity;

                output.push(
                    <div style={{padding:10, display:"flex", alignItems:"center"}} className="row cartItemRow" key={"cartItemRow_"+item.id}>
                        <div className="col-md-1 col-1">
                            {qty}
                        </div>
                        <div className="col-md-8 col-8">
                            <div style={{display:"flex"}}>
                                <span style={{fontSize: "14px"}}>{cartItem.title}</span>
                                <p className=" text-muted small">{cartItem.description}</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-3">
                            <strong id="summary" className="right">{_this.currency + " " + qty*cartItem.price}</strong>
                        </div>
                    </div>
               )
           }

        });

        return output;
    }

    renderBill(){
        let { t } = this.props;
        if(Object.keys(this.props.cart).length > 0){
            let bill =  this.calculateBill();
            this.totalBill = this.round(bill.billFinal);

            return(
                <div className="cartBillContainer">
                    <div>{t('lbl.item_total')}<span className='right'>{this.currency} {bill.billItemTotal}</span></div>

                    {(bill.cgst > 0) &&
                        <div>{t('lbl.cgst')}<span className='right'>{this.currency} {this.round(bill.cgst)}</span></div>
                    }

                    {(bill.sgst > 0) &&
                        <div>{t('lbl.sgst')}<span className='right'>{this.currency} {this.round(bill.sgst)}</span></div>
                    }

                    {(bill.service_tax > 0) &&
                        <div>{t('lbl.servicetax')}<span className='right'>{this.currency} {this.round(bill.service_tax)}</span></div>
                    }

                    {(bill.discount > 0) &&
                        <div>{t('lbl.discount')}<span className='right'>{this.currency} {this.round(bill.discount)}</span></div>
                    }

                    <div className="finalBill">{t('lbl.grandtotal')}<span className='right'>{this.currency} {this.round(bill.billFinal)}</span></div>

                </div>
            )
        }
    }

    calculateBill(){
        let {item_list} = this.props.menu;
        let billItemTotal = 0;
        let bill = {
            billItemTotal: 0,
            discount: 0,
            cgst: 0,
            sgst: 0,
            service_tax: 0,
            billFinal: 0
        };

        let item, qty, itemTotal, itemDiscount, itemAfterDiscount, itemCgst, itemSgst, itemServiceTax, itemFinalprice;

        Object.values(this.props.cart).map(cart_item => {
            item = item_list[cart_item.id];

            if(item.custom_quantity){
                Object.values(cart_item.custom_quantity).map(cq => {

                    qty = cq.quantity;

                    itemTotal = qty*item.custom_quantity[cq.id].price;
                    itemDiscount = itemTotal*item.discount/100;
                    itemAfterDiscount = itemTotal - itemDiscount;
                    itemCgst = itemAfterDiscount*item.cgst/100;
                    itemSgst = itemAfterDiscount*item.sgst/100;
                    itemServiceTax = itemAfterDiscount*item.service_tax/100;
                    itemFinalprice = itemAfterDiscount + itemCgst + itemSgst + itemServiceTax;

                    bill.billItemTotal += itemTotal;
                    bill.discount += itemDiscount;
                    bill.cgst += itemCgst;
                    bill.sgst += itemSgst;
                    bill.service_tax += itemServiceTax;
                    bill.billFinal += itemFinalprice;
                });
            }else{
                qty = cart_item.quantity;

                itemTotal = qty*item.price;
                itemDiscount = itemTotal*item.discount/100;
                itemAfterDiscount = itemTotal - itemDiscount;
                itemCgst = itemAfterDiscount*item.cgst/100;
                itemSgst = itemAfterDiscount*item.sgst/100;
                itemServiceTax = itemAfterDiscount*item.service_tax/100;
                itemFinalprice = itemAfterDiscount + itemCgst + itemSgst + itemServiceTax;

                bill.billItemTotal += itemTotal;
                bill.discount += itemDiscount;
                bill.cgst += itemCgst;
                bill.sgst += itemSgst;
                bill.service_tax += itemServiceTax;
                bill.billFinal += itemFinalprice;
            }
        });

        return bill;
    }

    makePaymentAndPlaceOrder(){
        if(this.state.deliveryAddress.trim().length === 0){
            this.setState({alertType: "warning_delivery_address"})
            return;
        }
        this.props.storeTotalBill(this.totalBill);
        this.props.storeInstructions(this.state.instruction);
        this.props.storeDeliveryAddress(this.state.deliveryAddress);
        this.props.history.push('/payOnline/currentbill')
    }

    round(value, precision=2) {
        let multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    renderPlaceOrderBlock(){
        let {user, userInputs, t} = this.props;

        if(!user.authorized){
            return (
                <>
                    <LoginModal show={this.state.showLoginModal} onHide={() => this.setState({showLoginModal: false})}/>
                    <button type="button" className="btn btn-block placeorderBtn" onClick={() => this.setState({showLoginModal: true})}>{t('btn.login')}</button>
                </>
            )
        }else if(user.authorized && (userInputs.food_service == 'DINE_IN_WALKIN')){
            return(
              <>
                <input type="text" className="form-control"  placeholder={t("placeholder.instruction")} value={this.state.instruction} onChange={(e) => this.setState({instruction: e.target.value})} />
                <span><span style={{color: "red"}}> {t('lbl.note')}: </span>{t('lbl.note_text')}</span>

                { !this.state.placingOrder ?
                    <button type="button" className="btn btn-block placeorderBtn" onClick={() => {this.props.storeInstructions(this.state.instruction);this.placeFoodOrder()}}>{t('btn.placeorder')}</button>
                    :
                    <button type="button" className="btn btn-block placeorderBtn" disabled>
                        <span style={{marginRight:10,marginBottom:2}} className="spinner-border spinner-border-sm"></span>
                        Placing Order...
                    </button>
                }
              </>
            )
        }else if(userInputs.food_service == 'TAKE_AWAY'){
            if(user.is_bill_pending && user.order_session_id && user.order_session_id !== null){
                return (
                    <>
                        <span style={{color: "red"}}>{t('lbl.pending_bill')}</span>
                        <button type="button" className="btn btn-block placeorderBtn" onClick={() => {this.props.storeInstructions(this.state.instruction);this.props.history.push('/pendingbill/')}}>{t('btn.clear_pending_bill')}</button>
                    </>
                )
            }else{
                return(
                    <>
                        <input type="text" className="form-control"  placeholder={t('placeholder.instruction')} value={this.state.instruction} onChange={(e) => this.setState({instruction: e.target.value})} />
                        <button type="button" className="btn btn-block placeorderBtn" onClick={() => {this.props.storeTotalBill(this.totalBill);this.props.storeInstructions(this.state.instruction);this.props.history.push('/payOnline/currentbill')}}>{t('btn.make_payment_place_order')}</button>
                    </>
                );
            }
        }
        else if(userInputs.food_service == 'DELIVERY'){
            if(user.is_bill_pending && user.order_session_id && user.order_session_id !== null){
                return (
                    <>
                        <span style={{color: "red"}}>{t('lbl.pending_bill')}</span>
                        <button type="button" className="btn btn-block placeorderBtn" onClick={() => {this.props.storeInstructions(this.state.instruction);this.props.history.push('/pendingbill/')}}>{t('btn.clear_pending_bill')}</button>
                    </>
                )
            }else{
                return(
                    <>
                        <input type="text" className="form-control"  placeholder={t('placeholder.instruction')} value={this.state.instruction} onChange={(e) => this.setState({instruction: e.target.value})} />
                        <input type="text" className="form-control"  placeholder={t('placeholder.deliveryAddress')} value={this.state.deliveryAddress} onChange={(e) => this.setState({deliveryAddress: e.target.value})} />
                        <button type="button" className="btn btn-block placeorderBtn" onClick={() => this.makePaymentAndPlaceOrder()}>{t('btn.make_payment_place_order')}</button>
                    </>
                );
            }
        }
    }

    renderAlert(){
        if(this.state.alertType !== null){
            let msg;
            if(this.state.alertType === "success"){
                msg =  "Order placed successfully";
                return <AlertModal show={true} type={this.state.alertType} msg={msg} onHide={() => {this.setState({alertType: null}); this.props.history.push('/menu/')}}/>
            }else if (this.state.alertType === "error") {
                msg = "Something went wrong!";
                return <AlertModal show={true} type={this.state.alertType} msg={msg} onHide={() => {this.setState({alertType: null}); this.props.history.push('/menu/')}}/>
            }else if (this.state.alertType === "warning_delivery_address") {
                msg = "Please enter Delivery Address";
                return <AlertModal show={true} type="warning" msg={msg} onHide={() => {this.setState({alertType: null})}}/>
            }
        }
    }

    render(){
        let { menu, cart, userInputs, user, t} = this.props;
        let cartCount = Common.getCartItemCount(cart);

        if(cartCount > 0){
            return(
                <>
                    <HeaderNavbar history={this.props.history} />
                    {this.renderAlert()}
                    <div className="container content2">
                        <div className="row">
                            <div className="col-md-12 text-center" style={{fontSize:24}}>{t('lbl.cart_page_label')}</div>
                        </div>

                        <h5 style={{fontSize:15, fontWeight:"bold", paddingTop:20, paddingLeft:10}}> {t('lbl.cart')} (<span>{cartCount}</span> {t('lbl.items')})</h5>
                        <div className="row">
                            <div className="col-md-12 cartDetailContainer">{this.renderCartDetail()}</div>
                        </div>
                        <h5 style={{fontSize:15, fontWeight:"bold", paddingTop:20, paddingLeft:10}}>{t('lbl.billdetails')}</h5>
                        <div className="row">
                            <div className="col-md-12">{this.renderBill()}</div>
                        </div>
                        <div className="row placeOrderBlock">
                            {this.renderPlaceOrderBlock()}
                        </div>
                    </div>
                </>
            );
        }else {
            return(
                <>
                    <HeaderNavbar history={this.props.history} />
                    {this.renderAlert()}
                    <div className="container content2">
                        <div className="row">
                            <div className="col-md-12 text-center" style={{fontSize:24}}>{t('lbl.cart_page_label')}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center" style={{fontSize:24, color:"red",marginTop:"10vh", marginBottom:"10vh"}}>{t('lbl.emptycart')}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button type="button" className="btn btn-block placeorderBtn" onClick={() => {this.props.history.push('/menu/')}}>{t('btn.gotomenu')}</button>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}


function matchDispatchToProps(dispatch){
    return bindActionCreators({
        emptyCart: cart_action.emptyCart,
        storePendingBillStatus: user_action.storePendingBillStatus,
        storeOrderSessionId: user_action.storeOrderSessionId,
        storeInstructions: user_input_action.storeInstructions,
        storeDeliveryAddress: user_input_action.storeDeliveryAddress,
        storeTotalBill: user_action.storeTotalBill
    }, dispatch)
}
function mapStateToProps(state){
    return{
        cart: state.cart,
        menu: state.menu,
        restaurant: state.restaurant,
        userInputs: state.userInputs,
        user: state.user,
    };
}

export default withTranslation()(connect(mapStateToProps, matchDispatchToProps)(Cart));
