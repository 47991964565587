import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


import HeaderNavbar from './components/HeaderNavbar';
import environmentHelper from '../revoex/helpers/environmentHelper';
import Menu from '../revoex/Menu_Cls';
import CONSTANT from '../revoex/constant';
import timeHelper from '../revoex/helpers/timeHelper';
import bg from '../images/bg.png';

import { withTranslation } from 'react-i18next';

class HotelHome extends Component{
    constructor(props) {
        super(props);
    }

    goToMenu = (event) => {

    }


    render(){
        const { t } = this.props;

        return(
          <div style={{paddingTop: '19vh'}} className="justify-content-center">
            {t('lbl.hotel_home')}
          </div>
        )

    }

}



function mapStateToProps(state){
    return{
        restaurant: state.restaurant,
        userInputs: state.userInputs
    };
}


export default withTranslation()(connect(mapStateToProps)(HotelHome));
