import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {user_action} from '../actions/user';
import {cart_action} from '../actions/cart';
import { withTranslation } from 'react-i18next';


import HeaderNavbar from './components/HeaderNavbar';
import FoodOrder from '../revoex/FoodOrder_Cls';
import Bill from '../revoex/Bill_Cls';
import AlertModal from './modals/AlertModal';

class PayOnline extends Component{

    constructor(props) {
        super(props);
        this.currency = this.props.restaurant.currency_symbol;
        this.state = {
            alertType: null
        }
    }

    placeOrder(){
        //TODO:: Place order, only if payment completed

        let _this = this;
        let {userInputs, restaurant, user, cart} = this.props;

        FoodOrder.placeOrder(userInputs, restaurant, user, cart).then( data => {
            _this.props.emptyCart();
            _this.props.storeOrderSessionId(data.order_session_id);
            _this.setState({alertType: "success1"});
        }).catch( err => {
            console.error("Error in Order Placing", err);
            _this.setState({alertType: "error"});
        });
    }

    updatePaymentStatus(){
        //TODO: if online payment completed successfully then update the status
        let req_data = {
            restaurant_id: this.props.restaurant.id,
            order_session_id: this.props.user.order_session_id,
            payment_completed: true,
            payment_mode: "NET_BANKING"
        };

        Bill.updatePaymentStatus(req_data).then( resp => {
            this.setState({alertType: "success2"});
            this.props.storePendingBillStatus(false);
        }).catch( err => {
            console.error("Error in updating bill status", err);
            this.setState({alertType: "error"});
        });

    }

    renderButtons(){
        let { t } = this.props;
        if(this.props.match.params.payment_for === 'pending'){
            return <button type="button" className="btn btn-primary btn-block" style={{marginBottom:20,borderRadius:5}} onClick = {() => this.updatePaymentStatus()}>{t('btn.make_pending_payments')}</button>
        }else{
            return <button type="button" className="btn btn-primary btn-block" style={{marginBottom:20,borderRadius:5}} onClick = {() => this.placeOrder()}>{t('btn.make_payment')}</button>
        }
    }
    renderAlert(){
        if(this.state.alertType !== null){
            let msg;
            if(this.state.alertType === "success1"){
                msg =  "Order placed successfully";
            }else if(this.state.alertType === "success2"){
                msg = "Thanks for making payment";
            }else if(this.state.alertType === "error"){
                 msg = "Something went wrong!";
            }
            return <AlertModal show={true} type={this.state.alertType} msg={msg} onHide={() => {this.setState({alertType: null}); this.props.history.replace('/menu/')}}/>
        }
    }

    render(){
        let { t } = this.props;
        return(
            <>
                <HeaderNavbar history={this.props.history} />
                {this.renderAlert()}
                <div style={{justifyContent:"center", backgroundColor:"white", padding:10, display:"flex", fontWeight:"bold", fontSize:15}}>{t('lbl.totalbill')}:  {this.currency+" "+this.props.user.total_bill}</div>
                <div className="container">

                    <div>
                        <div style={{padding:10, fontWeight:"bold"}}>{t('lbl.wallets')}</div>
                        <div style={{backgroundColor:"white", border:"solid", borderColor:"#eee", borderWidth:0.5, padding:10}}>Amazon Pay</div>
                        <div style={{backgroundColor:"white", border:"solid", borderColor:"#eee", borderWidth:0.5, padding:10}}>Phone Pe</div>
                        <div style={{backgroundColor:"white", border:"solid", borderColor:"#eee", borderWidth:0.5, padding:10}}>Paytm</div>
                    </div>
                    <div>
                        <div style={{padding:10,fontWeight:"bold"}}>{t('lbl.credit_debit_cards')}</div>
                        <div style={{backgroundColor:"white", border:"solid", borderColor:"#eee", borderWidth:0.5, padding:10}}>Add New Card</div>
                    </div>

                    {this.renderButtons()}
                </div>
            </>
        )
    }
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        emptyCart: cart_action.emptyCart,
        storePendingBillStatus: user_action.storePendingBillStatus,
        storeOrderSessionId: user_action.storeOrderSessionId,
    },dispatch);
}
function mapStateToProps(state){
    return{
        cart : state.cart,
        restaurant : state.restaurant,
        userInputs : state.userInputs,
        user : state.user,
    };
}
export default withTranslation()(connect(mapStateToProps,matchDispatchToProps)(PayOnline));
