import React, {Component} from 'react';
import {Modal, Button, Container, Row, Col} from 'react-bootstrap';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {user_input_action} from '../../actions/user_input';

import { withTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import CONSTANT from '../../revoex/constant';

class LanguageModal extends Component{

    constructor(props) {
        super(props);
        this.state={
            selected_lang: this.props.supported_language[0],
        }
    }

    languageChange = (lng) => {
        this.props.i18n.changeLanguage(lng);
        this.props.setLanguage(lng);
    }

    render(){
        let {supported_language, userInputs, t} = this.props;

        return(
            <Modal

              show={this.props.show}
              onHide={this.props.onHide}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t('lbl.change_lng')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                    <Row>
                      { supported_language.map(lng => {
                            return(
                              <Col xs={6} key={"lng_"+lng} style={{padding: 5}}>
                              <button type="button" className="btn btn-info btn-block"  onClick={() => this.setState({selected_lang: lng})}>
                                  {CONSTANT.LANGUAGE[lng]}
                                  {(this.state.selected_lang === lng) && <FaCheck />}
                              </button>
                              </Col>
                            )
                      })}
                    </Row>
                  </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.languageChange(this.state.selected_lang)}>{t('btn.apply')}</Button>
              </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state){
    return{
        supported_language: state.restaurant.supported_language,
        userInputs: state.userInputs
    };
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        setLanguage: user_input_action.setLanguage
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, matchDispatchToProps)(LanguageModal));
