import environmentHelper from './helpers/environmentHelper';
import requestHelper from './helpers/requestHelper';
import Menu from './Menu_Cls';

class Hotel{
    
    fetchRestaurantAndMenuData(name_url){
        let _this = this;
        return new Promise(function(resolve, reject) {
            let restaurant;
            requestHelper.request({
                url: environmentHelper.getUmsURL() + "/get_restaurant_details/",
                method:'POST',
                body:  { name_url }
            }).then(res_data => {
                res_data.currency_symbol = _this.currencySymbol(res_data.currency);
                restaurant = res_data;
                return Menu.fetchRestaurantMenu(res_data.id);
            }).then(menu_data => {
                let {item_list, category_list, category_item_map} = menu_data;
                let menu = {item_list, category_list, category_item_map};
                resolve({restaurant, menu});
            }).catch( err => {
                reject(err);
            });
        });
    }
    
    fetchHotelAndRestaurantData(hotel_id){
        return requestHelper.request({
            url: environmentHelper.getUmsURL() + "/get_hotel_details/",
            method:'POST',
            body:  { hotel_id }
        });
    }
    
    currencySymbol(curency){
        if(curency === 'EUR')       return "€";
        else if(curency === 'USD')  return "$";
        else if(curency === 'INR')  return "₹";
    }
    
}

export default new Hotel();