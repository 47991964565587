import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import {connect} from 'react-redux';

import HeaderNavbar from './components/HeaderNavbar';
import Bill from '../revoex/Bill_Cls';
import {user_action} from '../actions/user';
import {bindActionCreators} from 'redux';
import { withTranslation } from 'react-i18next';

class PendingBill extends Component{
    constructor(props) {
        super(props);
        this.currency = this.props.restaurant.currency_symbol;
        this.state = {
            payAtCounter: false,
            paymentDetails:{bill_details:{},order_details:[]},
            bill_fetched:false
        };

    }
    payAtCounter(){
        this.setState({payAtCounter: true});
        setTimeout(() => {
            this.setState({payAtCounter: false});
            this.props.history.replace('/restaurant');
        }, 3000);

        let {user, restaurant} = this.props;
        let timer = setInterval(() => {
            Bill.checkBillStatus(restaurant.id, user.order_session_id).then(resp => {
                if(resp.payment_completed){
                    this.props.storePendingBillStatus(false);
                    this.props.storeOrderSessionId(null);
                    clearInterval(timer);
                }
            }).catch(err => {
                console.debug(err);
            });
        }, 10000);
    }

    componentWillMount(){
        let {user,restaurant} = this.props;
        Bill.fetchBill(restaurant.id, user.order_session_id).then(data => {
            this.setState({bill_fetched:true,paymentDetails:{bill_details:data.bill_details,order_details:data.order_details}});
            this.props.storeTotalBill(data.bill_details.final_value);
        }).catch(err => {
            console.debug(err);
        });
    }

    renderLoader(){
        let { t } = this.props;
        if(!this.state.bill_fetched || this.state.payAtCounter){
            return (
                <div style={{position:'fixed',left:"50%",top:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
                <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">{t('sr.loading_payments')}</span>
                </Spinner>
                </div>
            )
        }
    }

    renderItems(){
        let {paymentDetails} = this.state;
        let { t } = this.props;
        if(this.state.bill_fetched) {
            return (
                <>
                    <div className="col-md-12 text-center" style={{fontSize:24}}>{t('lbl.pending_bill_page_label')}</div>
                    <h5 style={{fontSize:15, fontWeight:"bold", paddingTop:20, paddingLeft:10}}>{t('lbl.myorders')}</h5>
                    <div className="col-md-12 cartDetailContainer">
                        {
                            paymentDetails.order_details ?
                            paymentDetails.order_details.map((value, i) =>
                            value.custom_quantity ?
                            value.custom_quantity.map((item, j) =>
                            this.renderOrderItem(item.qty, value.item_name + "("+item.title+")",  item.price, i+"_"+j))
                            :
                            this.renderOrderItem(value.quantity, value.item_name, value.price, i))
                            :
                            null
                        }
                    </div>
                </>
            );
        }
    }

    renderBill(){
      let {paymentDetails} = this.state;
      let { t } = this.props;
      if(this.state.bill_fetched){
        return (
          <>
            <h5 style={{fontSize:15, fontWeight:"bold", paddingTop:20, paddingLeft:10}}>{t('lbl.billdetails')}</h5>
            <div className="cartBillContainer">
              <div>{t('lbl.item_total')}<span className='right'>{this.currency} {paymentDetails.bill_details.price}</span></div>
              <div>{t('lbl.cgst')}<span className='right'>{this.currency} {paymentDetails.bill_details.cgst_value}</span></div>
              <div>{t('lbl.sgst')}<span className='right'>{this.currency} {paymentDetails.bill_details.sgst_value}</span></div>
              <div>{t('lbl.servicetax')}<span className='right'>{this.currency} {paymentDetails.bill_details.service_charge_value}</span></div>
              { paymentDetails.discount_value > 0 ?
                  <div>{t('lbl.discount')}<span className='right'>{this.currency} {paymentDetails.bill_details.discount_value}</span></div>
                  :null
              }
              <div className="finalBill">{t('lbl.grandtotal')}<span className='right'>{this.currency} {paymentDetails.bill_details.final_value}</span></div>
            </div>
            <div style={{paddingTop:20,paddingBottom:20}}>
              <button type="button" className="btn btn-primary btn-block" style={{marginBottom:20,borderRadius:5}} onClick = {()  =>  this.payAtCounter()}>{t('btn.payatcounter')}</button>
              <button type="button" className="btn btn-primary btn-block" style={{marginBottom:20,borderRadius:5}} onClick = {() => this.props.history.replace('/payOnline/pending')}>{t('btn.payonline')}</button>
            </div>
          </>
        );
      }
    }

    renderOrderItem(quantity, name, price, key){
      return (<div style={{padding:10, display:"flex", alignItems:"center"}} className="row cartItemRow" key={key}>
          <div className="col-md-1 col-1">
              {quantity}
          </div>
          <div style={{display:"flex"}} className="col-md-8 col-8">
              <div style={{display:"flex", justifyContent:"center"}}>
                  <span style={{fontSize: "14px"}}>
              {name}
                  </span>
              </div>
          </div>
          <div className="col-md-3 col-3">
              <strong id="summary" className="right">
                {this.currency} {quantity*price}
              </strong>
          </div>
      </div>)
    }


    render(){
        return(
            <>
                <HeaderNavbar history={this.props.history} />
                <div className="container content2" style={{height:"100vh", backgroundColor:"#f7f2f2"}}>
                    { this.renderLoader()}
                    { this.renderItems()}
                    { this.renderBill()}
                 </div>
            </>
        )
    }
}

function mapStateToProps(state){
    return{
        restaurant : state.restaurant,
        user:state.user,
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        storePendingBillStatus: user_action.storePendingBillStatus,
        storeOrderSessionId: user_action.storeOrderSessionId,
        storeTotalBill: user_action.storeTotalBill
    },dispatch);
}

export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(PendingBill));
