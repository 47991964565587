import React, {Component} from 'react';
import {Modal, Button, Container, Row, Col} from 'react-bootstrap';
import AddCartButton from '../components/AddCartButton';
import {connect} from 'react-redux';

class CustomQuantityModal extends Component{

    constructor(props) {
        super(props);
        this.currency = this.props.restaurant.currency_symbol;
    }

    render(){
        let { item, t } = this.props;
        return(

            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    { item.veg ?
                        <img style={{marginRight:10,marginBottom:10}} src={require('../../images/veg_icon.png')} />
                        :
                        <img style={{marginRight:10,marginBottom:10}} src={require('../../images/non_veg_icon.png')} />
                    }{item.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Container>
                    { Object.keys(item.custom_quantity).map(id => {
                        return (
                            <Row style={{alignItems:"center"}} key={"cqm_"+id}>
                              <Col xs={5}>
                                {item.custom_quantity[id]['title']}
                              </Col>
                              <Col xs={2}>
                                {this.currency} {item.custom_quantity[id]['price']}
                              </Col>
                              <Col xs={5} style={{padding:5}}>
                                <AddCartButton item={item} custom_quantity_id={id}/>
                              </Col>
                            </Row>
                        )
                    })}
                  </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.props.onHide}>{ t('btn.ok')}</Button>
              </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state){
    return{
        restaurant: state.restaurant
    };
}

export default connect(mapStateToProps, null)(CustomQuantityModal);
