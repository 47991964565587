import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {user_input_action} from './actions/user_input';
import {user_action} from './actions/user';
import {storeRestaurantDetail} from './actions/restaurant';
import {menu_action} from './actions/menu';

import RestaurantHome from './pages/RestaurantHome';
import HotelHome from './pages/HotelHome';
import Error from './pages/Error';
import Menu from './pages/Menu';
import Cart from './pages/Cart';
import PendingBill from './pages/PendingBill';
import PayOnline from './pages/PayOnline';

import URLParser from './revoex/helpers/URLParser';
import Bootstrap from './revoex/Bootstrap';
import Account from './revoex/Account';

class App extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            error: false
        }
    }

    componentDidMount(){
        this.urlParam = URLParser.parse();
        if(!(this.urlParam.hotel_name_url || this.urlParam.restaurant_name_url)){
            this.setState({error: true});
            return;
        }
        this.props.setUrlParams(this.urlParam);


        Bootstrap.initialize(this.urlParam).then((data) => {
            console.log('Hotel/Restaurant data fetched.');
            this.props.storeRestaurantMenu(data.menu);
            this.props.storeRestaurantDetail(data.restaurant);
            this.setState({fetched: true});
            this.authorizeDiner();
        }).catch((err)=>{
            console.error('Error: ', err);
            this.setState({error: true});
        });
    }

    authorizeDiner(){
        Account.isAuthorized().then( () => {
            console.info('User authorized');
            this.props.storeAuthStatus(true);
        }).catch( msg => {
            console.warn("Diner authorization fails");
            this.props.storeAuthStatus(false);
        });
    }

    render(){
        if(this.state.fetched || this.state.error){

            return (
                <Router>
                    <Switch>

                        <Route path="/Error/" component={Error}/>
                        { ((!this.urlParam.restaurant_name_url && !this.urlParam.hotel_name_url) || this.state.error) && <Redirect to="/Error/" /> }

                        <Route path="/rhome/" component={RestaurantHome}/>
                        <Route path="/hhome/" component={HotelHome}/>
                        <Route path="/menu/:tcatid?" component={Menu}/>
                        <Route path="/cart/" component={Cart}/>
                        <Route path="/pendingbill/" component={PendingBill}/>
                        <Route path="/payOnline/:payment_for" component={PayOnline}/>

                        { (this.urlParam.restaurant_name_url) && <Redirect to="/rhome/" /> }
                        { (!this.urlParam.restaurant_name_url && this.urlParam.hotel_name_url) && <Redirect to="/hhome/" /> }

                    </Switch>
                </Router>
            );
        }else {
            return <div>Loading.....</div>
            //<div className="d-flex justify-content-center"><div className="spinner-border text-danger"></div></div>
        }
    }
}

function matchDispatchToProps(dispatch){
    return bindActionCreators({
        setUrlParams: user_input_action.setUrlParams,
        storeRestaurantDetail: storeRestaurantDetail,
        storeRestaurantMenu: menu_action.storeRestaurantMenu,
        storeAuthStatus: user_action.storeAuthStatus
    }, dispatch)
}

export default connect( null, matchDispatchToProps)(App);
