
class URLParser{
    // tbl = table_no
    // rum = room_no
    // tkw  = takeway
    // idn = internal guest dinein
    // edn = external visitor dinein
    // rdn = room dinein
    
    parse(){
        let output = {};
        let path = window.location.pathname;
        
        let hotel = path.match(/hotel\/[\w-]+/);
        let restaurant = path.match(/restaurant\/[\w-]+/);
        
        let table = path.match(/tbl__\w+/);
        let room = path.match(/rum__\w+/);
        
        let takeaway = path.match(/tkw__\w+/);
        let internal_dinein = path.match(/idn__\w+/);
        let external_dinein = path.match(/edn__\w+/);
        let room_dinein = path.match(/rdn__\w+/);

        if(hotel){
            hotel = hotel[0].substring(5);
            output.hotel_id = hotel;
            localStorage.setItem('htl', hotel);
        }else{
            output.hotel_id = localStorage.getItem('htl');
        }
        
        if(restaurant){
            restaurant = restaurant[0].split("/")[1];
            output.restaurant_name_url = restaurant;
            localStorage.setItem('rst', restaurant);
        }else{
            output.restaurant_name_url = localStorage.getItem('rst');
        }
        
        
        if(table){
            table = table[0].substring(5);
            output.table_no = table;
            localStorage.setItem('tbl', table);
        }
        if(room){
            room = room[0].substring(5);
            output.room_no = room;
            localStorage.setItem('rim', room);
        }
        
        if(takeaway){
            output.food_service = 'TAKE_AWAY';
            localStorage.setItem('food_service', 'TAKE_AWAY');
        }else if (internal_dinein) {
            output.food_service = 'DINE_IN_INTERNAL';
            localStorage.setItem('food_service', 'DINE_IN_INTERNAL');
        }else if (external_dinein) {
            output.food_service = 'DINE_IN_WALKIN';
            localStorage.setItem('food_service', 'DINE_IN_WALKIN');
        }else if (room_dinein) {
            output.food_service = 'IN_ROOM_DINING';
            localStorage.setItem('food_service', 'IN_ROOM_DINING');
        }
        
        return output;
    }
    
}

export default new URLParser();