class Device{
    
    initalize(){
        console.log('Initializing Device');
        let _this = this;

        return new Promise(function(resolve, reject) {
            let device_id = localStorage.getItem('device_id');
            if(device_id !== null) {
                console.debug("Device_id found: " + device_id);
            }else{
                device_id = _this.generateGUID();
                localStorage.setItem('device_id', device_id)
                console.debug("Device_id generated: " + _this.device_id);
            }
            _this.device_id = device_id;
            resolve(true);
        });
    }
    
    
    generateGUID(){
        let d = new Date().getTime();
        const guid = 'xyxxx-xxxx-4xxxyxx'.replace(/[xy]/g, c => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return guid;
    }
    
    getDeviceId(){
        return this.device_id;
    }
    
}

export default new Device();