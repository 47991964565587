import {combineReducers} from 'redux';
import restaurant from './restaurantReducer.js';
import menu from './menuReducer.js';
import cart from './cartReducer.js';
import order from './orderReducer.js';
import userInputs from './userInputReducer.js';
import user from './userReducer.js';

const allReducers = combineReducers({
    restaurant, menu, cart, order, userInputs, user
});

export default allReducers;
