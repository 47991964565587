import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {cart_action} from '../../actions/cart';
import { withTranslation } from 'react-i18next';

class AddCartButton extends Component{
    constructor(props) {
        super(props);
        let {cart, item, custom_quantity_id} = this.props;

        this.state = {
            qty: this.itemCountInCart()
        };
    }

    itemCountInCart(){
        let {cart, item, custom_quantity_id} = this.props;

        if(cart[item.id] && cart[item.id]['custom_quantity']){
            if(cart[item.id]['custom_quantity'][custom_quantity_id]){
                return cart[item.id]['custom_quantity'][custom_quantity_id]['quantity'];
            }else{
                return 0;
            }
        }else if(cart[item.id]){
            return cart[item.id]['quantity'];
        }else{
            return 0;
        }
    }

    incQty(){
        this.setState({qty: this.state.qty + 1});

        let payload = {id: this.props.item.id}
        if(this.props.custom_quantity_id){
            payload.custom_quantity_id = this.props.custom_quantity_id;
        }
        this.props.addToCart(payload);
    }
    dcrQty(){
        if(this.state.qty == 0) return;

        this.setState({qty: this.state.qty - 1});

        let payload = {id: this.props.item.id}
        if(this.props.custom_quantity_id){
            payload.custom_quantity_id = this.props.custom_quantity_id;
        }
        this.props.removeFromCart(payload);
    }

    render(){
        return(
            <div className="addCartBtnWrapper">
                <div className="dcr" onClick={() => this.dcrQty()}>-</div>
                <div className="qty">{this.state.qty}</div>
                <div className="incr" onClick={() => this.incQty()}>+</div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return{
        cart : state.cart
    };
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        addToCart: cart_action.addToCart,
        removeFromCart: cart_action.removeFromCart
    }, dispatch)
}
export default withTranslation()(connect(mapStateToProps, matchDispatchToProps)(AddCartButton));
