import environmentHelper from './helpers/environmentHelper';
import requestHelper from './helpers/requestHelper';
import Device from './util/Device';


class Account{
    constructor() {
        this.auth_token = null;
    }

    initalize(){
        console.log('Initializing Account');
        let _this = this;

        return new Promise(function(resolve, reject) {
            let token = localStorage.getItem('token');

            if(!token){
                console.debug('Token not found');
            }else{
                _this.auth_token = token;
                requestHelper.auth_token = token;
            }
            resolve();
        });
    }

    updateSecretToken({token, expiry}){
        this.auth_token = token;
        this.auth_token_expiry_time = expiry;
        requestHelper.auth_token = token;
        localStorage.setItem('token', token);
    }

    deleteSecretToken(){
        this.auth_token = null;
        localStorage.removeItem('token');
    }

    isAuthorized() {
        let _this = this;

        return new Promise(function(resolve, reject) {
            if( _this.auth_token == undefined || _this.auth_token == null || _this.auth_token == ''){
                reject('Local token not found');
                return;
            }
            _this.verifyToken().then(data => {
                resolve('Token verified');
            }).catch( err => {
                reject(err);
            });
        });
    }

    signout() {
        let _this = this;
        return new Promise(function(resolve, reject) {
            requestHelper.requestWithAuth({
                url: environmentHelper.getUmsURL() + "/logout/",
                method:'POST'
            }).then(() => {
                _this.deleteSecretToken();
                resolve();
            }).catch( err => {
                reject(err);
            });
        });
    }

    verifyToken(){
        return requestHelper.requestWithAuth({
            url: environmentHelper.getUmsURL() + "/verify_diner_token/",
            method:'POST',
            body:  { device_id: Device.getDeviceId()}
        });
    }

    requestForOTP(phone, resend){
        console.log('Requesting for OTP: ' + phone);
        let url = (resend === true) ? environmentHelper.getUmsURL() +  "/resend_diner_otp/" : environmentHelper.getUmsURL() +  "/send_diner_otp/";

        return requestHelper.request({
            url: url,
            method:'POST',
            body:  { mobile: phone, device_id: Device.getDeviceId()}
        });
    }

    verifyOTP(phone, otp){
        let _this = this;
        return new Promise(function(resolve, reject) {
            requestHelper.request({
                url: environmentHelper.getUmsURL() + "/verify_diner_otp/",
                method:'POST',
                body:  { mobile: phone, otp: otp, device_id: Device.getDeviceId()}
            }).then(data => {
                _this.updateSecretToken(data);
                resolve('otp verified');
            }).catch( err => {
                reject(err);
            });
        });
    }

}

export default new Account();
