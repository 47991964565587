import { environment} from '../../app.config.json';
import requestHelper from './requestHelper';

class environmentHelper{

    constructor() {
        this.configURL = this.getConfigURL(environment);
        this.config = {};
    }
    
    initalize(){
        let _this = this;
        console.log('Initializing environmentHelper');

        return new Promise(function(resolve, reject) {
            requestHelper.requestEnvConfig({url: _this.configURL }).then(data => {
                _this.config = data;
                resolve();
            }).catch( err => {
                reject('App config server is down ', err);
            });
        });
    }
    
    getConfigURL(env){
        if(env === 'italy'){
            return 'http://config.revo-ex.com/italy.restrodine.json'
        }else if(env === 'production'){
            return 'http://config.revo-ex.com/prod.restrodine.json';
        }if(env === 'qa'){
            return 'http://config.revo-ex.com/qa.restrodine.json'
        }
    }
    
    getUmsURL(){
        return this.config.url.ums;
    }
    
    getCmsURL(){
        return this.config.url.cms;
    }
    
    getImgURL(){
        return this.config.url.img;
    }
    
}

export default new environmentHelper();