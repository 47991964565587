export default {
    DINE_TYPE:{
        DINE_IN_INTERNAL: 'Restaurant Dining',
        IN_ROOM_DINING: 'In Room Dining',
        DINE_IN_WALKIN: 'DINE-IN',
        TAKE_AWAY: 'TAKEAWAY',
        ADVANCE_BOOKING: 'Advance Booking',
        DELIVERY: 'DELIVERY'
    },
    LANGUAGE:{
        en: 'English',
        hi: 'हिन्दी'
    },
    QR_APP_BANNERS:[
        "banner_placeholder1.png",
        "banner_placeholder2.png"
    ]
};
