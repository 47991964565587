
const orderReducer = function(state = {}, action ) {

    switch (action.type) {
        case 'PLACE_ORDER':
            return {status: action.payload};

        default:
            return state;
    }
}


export default orderReducer;