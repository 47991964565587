import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import Common from '../../revoex/util/Common';

class CartSVG extends Component{
    constructor(props) {
        super(props);
    }

    render(){
        let cartCount = Common.getCartItemCount(this.props.cart);
        
        if(cartCount > 0){
            return(
                <Link to="/cart" id="cartIconWrapper">
                    <span className='badge badge-warning' id='lblCartCount'>{cartCount}</span>
                    <img id="cartIcon" src={require("../../images/cart.png")} />
                </Link>
            )
        }else{
            return <img id="cartIcon" src={require("../../images/cart.png")} />
        }
    }
}

function mapStateToProps(state){
    return{
        cart: state.cart
    };
}
export default connect(mapStateToProps)(CartSVG);
