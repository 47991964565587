export const menu_action = {
    storeRestaurantMenu: (data)=> {
        return{
            type: 'STORE_RESTAURANT_MENU',
            payload: data
        }
    },
    
}


