import React, {Component} from 'react';
import {Modal, Button, Container, Row, Col} from 'react-bootstrap';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {user_input_action} from '../../actions/user_input';


import { AiOutlineCheckCircle } from 'react-icons/ai';
import CONSTANT from '../../revoex/constant';

class AlertModal extends Component{

    constructor(props) {
        super(props);
    }

    languageChange = (lng) => {
    }

    render(){
        let {type, msg} = this.props;
        let title, bgcolor;

        if(type === "success"){
            title = "Success!";
            bgcolor = "green";
        }else if(type === "warning"){
            title = "Warning!";
            bgcolor = "#c4ae14";
        }else if(type === "error"){
            title = "Error!";
            bgcolor = "red";
        }


        return(
            <Modal
              show={this.props.show}
              onHide={this.props.onHide}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{color: bgcolor}}
            >
                <div className="modal-header text-center">
                    <h3 className="modal-title w-100">
                        { (type === "success") && <AiOutlineCheckCircle /> }
                        {title}

                    </h3>
                </div>
              <Modal.Body>
                <Container>{msg}</Container>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.props.onHide}>OK</Button>
              </Modal.Footer>
            </Modal>
        )
    }
}


function matchDispatchToProps(dispatch){
    return bindActionCreators({
        setLanguage: user_input_action.setLanguage
    }, dispatch)
}

export default connect(matchDispatchToProps)(AlertModal);
