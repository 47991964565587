import React, {Component} from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {user_input_action} from '../actions/user_input';
import CONSTANT from '../revoex/constant';
import environmentHelper from '../revoex/helpers/environmentHelper';
import { withTranslation } from 'react-i18next';


import {Carousel} from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import LanguageModal from './modals/LanguageModal';

class RestaurantHome extends Component{
    constructor(props) {
        super(props);
        this.userInputs = this.props.userInputs;
        this.state = {
            food_service: this.userInputs.food_service ? this.userInputs.food_service : "",
            showWarning: false,
            showLanguageModal: false
        }
    }

    renderCorousels(){
        const {qr_app_banners: remote_banners} = this.props.restaurant;
        const banners = remote_banners ? remote_banners : CONSTANT.QR_APP_BANNERS;
        const imgHost = environmentHelper.getImgURL();

        return (
            <Carousel>
                {banners.map((banner, i) => {
                    return (
                        <Carousel.Item key={"banner_"+i}>
                            {remote_banners
                                ? <img className="d-block w-100" src={imgHost + banner} />
                                : <img className="d-block w-100" src={require(`../images/${banner}`)} />}
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        )
    }

    onDineTypeChanged = (dineType) => {
        this.props.storeDineType(dineType);
        this.setState({food_service: dineType});
    }

    renderDineModes(){
        let dineTypes = Object.keys(this.props.restaurant.services.FOOD_SERVICES);
        let {userInputs, t} = this.props;
        let lbl_choose_order_mode = t("lbl.choose_order_mode")
        let elm = [];

        dineTypes.map(dineType => {
            elm.push(
                <div className="col-6" style={{padding: 5}} key={"dine_type_"+dineType}>
                    <button type="button" className="btn btn-block" onClick={() => this.onDineTypeChanged(dineType)}>
                        {CONSTANT.DINE_TYPE[dineType]}
                        {(userInputs.food_service === dineType) && <FaCheck />}
                    </button>
                </div>
            )
        });
        return (
            <>
                <div className="row" style={{marginTop: 20}}><div className="col-12 text-center"><h4>{lbl_choose_order_mode}</h4></div></div>
                <div className="row">{elm}</div>
            </>
        );
    }

    goToMenu = (id) => {
        if(this.state.food_service.trim() !== ""){
            this.props.history.push('/menu/'+id);
        }else{
            this.setState({showWarning: true});
        }
    }

    renderCategories(side){
        let {category_list} = this.props;
        let cat_list_array = Object.values(category_list);
        let parent_categories = cat_list_array.filter(obj => obj.parent === null && obj.enabled);
        let imgHost = environmentHelper.getImgURL();
        let imageLink;
        let cats = [];
        for(let i=0; i < parent_categories.length; i++){
            if( ((side === "left") && (i%2 == 1))
                ||
                 ((side === "right") && (i%2 == 0))
            ){
                if(parent_categories[i].image == null){
                    imageLink = require('../images/category_placeholder.png');;
                }
                else{
                    imageLink = imgHost + parent_categories[i].image;
                }

                cats.push(
                    <div key={parent_categories[i].id} style={{paddingBottom: 15, margin: 5}}>
                        <div onClick={() => this.goToMenu(parent_categories[i].id)} style={{position:"relative", display:"flex", justifyContent:"center",  backgroundImage: "url(" + imageLink + ")", backgroundPosition:"center", backgroundSize: 'cover', backgroundRepeat: 'no-repeat',  borderRadius:10, height: 200}} >
                            <div style={{paddingLeft:8,paddingRight:8, paddingTop:2,paddingBottom:2, borderRadius:2, position:"absolute", bottom:10, backgroundColor:"white", textAlign:"center",fontSize:20, color: "black", fontWeight: "bold"}}>{parent_categories[i].title}</div>
                        </div>
                    </div>)
                }
            }
        return cats;
    }


    render(){
        let {restaurant, category_list, t} = this.props;
        return(
            <div className="justify-content-center">
                <LanguageModal show={this.state.showLanguageModal} onHide={() => this.setState({showLanguageModal: false})}/>

                <div style={{position:"absolute", top:0, right:20, zIndex:100, borderRadius:5, padding:5}}>
                    <span onClick={() => this.setState({showLanguageModal: true})}  style={{fontSize:25}}>&#127760;</span>
                </div>

                {this.renderCorousels()}

                {this.state.showWarning && <div role="alert" className="alert alert-danger"><span>{t('error.dinetype')}</span></div>}

                {this.renderDineModes()}

                {(Object.keys(category_list).length === 0) ?
                    <div className="row">
                        <div className="col-12" style={{margin:30}}>{restaurant.name} is not ready for Service.</div>
                    </div>
                :
                    <div className="row">
                        <div className="col-6">
                            <div key={"first_div"} style={{height: 80}}></div>
                            {this.renderCategories("left")}
                        </div>
                        <div className="col-6">
                            {this.renderCategories("right")}
                        </div>
                    </div>
                }
          </div>
        )
    }

}

function mapStateToProps(state){
    return{
        restaurant: state.restaurant,
        category_list: state.menu.category_list,
        userInputs: state.userInputs
    };
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        storeDineType: user_input_action.storeDineType
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, matchDispatchToProps)(RestaurantHome));
