import React, {Component} from 'react';
import {connect} from 'react-redux';
import AddCartButton from './AddCartButton';
import {Row, Col} from 'react-bootstrap';
import environmentHelper from '../../revoex/helpers/environmentHelper'
import CustomQuantityModal from '../modals/CustomQuantityModal';
import { withTranslation } from 'react-i18next';

class MenuItemCard extends Component{

    constructor(props) {
        super(props);
        this.currency = this.props.restaurant.currency_symbol;
        this.state = {showCustomQuantityModal: false}
    }
    getUniqueKey(i){
        let key = + new Date() + '-' + i;
        return key;
    }

    renderCartButton(bookingAllowed, item){
        let { t } = this.props;
        if(!bookingAllowed) return null;
        if(item.custom_quantity){
            return (
                <div>
                    <Col>
                        <button type="button" className="btn" style={{width:"100%",backgroundColor:"white", color:"#2b76ab", borderColor:"#ccc",fontSize:"12px", fontWeight:"bold" }} onClick={() => this.setState({showCustomQuantityModal: true})} >{t('btn.add')} +</button>
                        <div style={{textAlign:"center",color:"grey",fontStyle:"italic"}}>{t("lbl.customisable")}</div>
                    </Col>
                    <CustomQuantityModal item={item} t={t} show={this.state.showCustomQuantityModal} onHide={() => this.setState({showCustomQuantityModal: false})}/>
                </div>
            )
        }else{
            return (<div><AddCartButton item={item} /></div>)
        }
    }
    renderUnavailableButton(){
        let { t } = this.props;
        return(
            <button className="btn" style={{width:"100%",backgroundColor:"white", color:"#555", borderColor:"#ccc",fontSize:"12px", fontWeight:"bold" }} disabled>
                {t('btn.unavailable')}
            </button>
        );
    }


    render(){
        let {itemId, item_list, userInputs} = this.props;
        let item = item_list[itemId];

        return(
            <>
                {item.image?
                    <Row className="menu_item_card" key={this.getUniqueKey(item.id)}>
                        <Row className="col-7" style={{alignItems:"center"}}>
                            <div>
                                <Col>{item.veg ?
                                    <div><img src={require('../../images/veg_icon.png')} /></div>
                                    :
                                    <div><img src={require('../../images/non_veg_icon.png')} /></div>
                                }
                                <div style={{fontSize: "16px", paddingTop:10, wordWrap:"break-word", fontWeight:"bold"}}>{item.title}</div>
                                <div style={{fontSize: "14px", paddingTop:3, color:"grey"}}>{this.currency} {item.price}</div>
                                <div style={{fontSize: "13px", wordWrap:"break-word",paddingTop:3, color:"grey"}}>{item.description}</div></Col>
                            </div>
                        </Row>
                        <Col className="col-5">
                            <div style={{height:"100%", display:"flex",justifyContent:"center", alignItems:"center"}}>
                                <div>
                                    <div style={{ display:"flex",justifyContent:"center", alignItems:"center"}}><img className="menu-image" src={environmentHelper.getImgURL() + item.image} /></div>
                                    <div style={{ display:"flex",justifyContent:"center", alignItems:"center", paddingTop:5}}>
                                        {item.available ? this.renderCartButton(userInputs.bookingAllowed, item) : this.renderUnavailableButton()}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                :
                <div className="menu_item_card" key={this.getUniqueKey(item.id)} style={{display:"flex", justifyContent:"center"}}>
                    <Row className="col-7" style={{alignItems:"center"}}>
                        <div>
                            <Col>{item.veg ?
                                <div><img src={require('../../images/veg_icon.png')} /></div>
                                :
                                <div><img src={require('../../images/non_veg_icon.png')} /></div>
                            }
                            <Row>
                                <div style={{fontSize: "16px", paddingTop:10, wordWrap:"break-word", fontWeight:"bold"}}>{item.title}</div>
                            </Row>

                            <div style={{fontSize: "14px", paddingTop:3, color:"grey"}}>{this.currency} {item.price}</div>
                            <div style={{fontSize: "13px", wordWrap:"break-word",paddingTop:3, color:"grey"}}>{item.description}</div></Col>
                        </div>
                    </Row>
                    <Row className="col-5" style={{justifyContent:"center", alignItems:"center"}}>
                        {item.available ? this.renderCartButton(userInputs.bookingAllowed, item) : this.renderUnavailableButton()}

                    </Row>

                </div>
            }
            </>
        )
    }
}

function mapStateToProps(state){
    return{
        item_list : state.menu.item_list,
        userInputs: state.userInputs,
        restaurant: state.restaurant
    };
}

export default withTranslation()(connect(mapStateToProps)(MenuItemCard));
