import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import HeaderNavbar from './components/HeaderNavbar';
import MenuFilterBar from './components/MenuFilterBar';
import MenuItemCard from './components/MenuItemCard';
import { withTranslation } from 'react-i18next';

class Menu extends Component{

    constructor(props) {
        super(props);
    }

    displayMenu(){
        let {item_list, category_item_map} = this.props.menu;
        let {selected_menu_category} = this.props.userInputs;

        if(selected_menu_category && category_item_map[selected_menu_category]){
            let menuArr = [];
            let items = category_item_map[selected_menu_category];

            items.map(itemId => {
                    if(item_list[itemId].enabled)
                        menuArr.push(<div className="col-12" key={itemId}><MenuItemCard itemId={itemId} /></div>)
            });

            return menuArr;
        }else{
            return null;
        }
    }

    displayBookingAllowedWarning(){
        let {bookingAllowed, bookingWarningMsg} = this.props.userInputs;

        if(!bookingAllowed){
            return(
                <div style={{position: 'fixed',top: '150px', left: '5px', margin: '5px', zIndex: 10}}>
                    <div role="alert" className="alert alert-danger"><span>{bookingWarningMsg}</span></div>
                </div>
            );
        }
    }

    displayPaymentPopup(){
        let { t } = this.props;
        if(this.props.user.is_bill_pending){
            return(
                <div className="col-lg-4 col-md-6 col-sm-12 col-12 payment_reminder" style={{position: 'fixed',bottom: 0,width:"100%"}}>
                    <div style={{padding:10,textAlign:'center'}}>
                        <Link to="/pendingbill" style={{color: "white", textDecoration: "none"}}>{t('btn.payment_popup_text')}</Link>
                    </div>
                </div>
            );
        }
    }

    render(){
        return(
            <>
                <HeaderNavbar history={this.props.history} />
                <MenuFilterBar tcatid={this.props.match.params.tcatid}/>

                <div id="content1" style={{marginBottom:150}}>
                    {  this.displayBookingAllowedWarning()  }
                    {  this.displayMenu()  }
                    {  this.displayPaymentPopup()  }
                </div>
            </>
        )
    }

}

function mapStateToProps(state){
    return{
        menu: state.menu,
        user: state.user,
        userInputs: state.userInputs
    };
}
export default withTranslation()(connect(mapStateToProps)(Menu));
