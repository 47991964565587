import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import CartSVG from './CartSVG';
import { Link } from 'react-router-dom';
import {user_action} from '../../actions/user';

class HeaderNavbar extends Component{
    //https://www.w3schools.com/howto/howto_js_sidenav.asp

    constructor(props) {
        super(props);
        this.pathname = window.location.pathname;
    }


    displayBackButton(){
        if(this.pathname.includes('/cart')
            || this.pathname.includes('/menu')
            || this.pathname.includes('/pendingbill')){
            return <img id="cartIcon" onClick={this.navigateBack} src={require("../../images/back.svg")} />
        }
    }

    displayCartButton(){
        if(this.pathname.includes('/cart')
            || this.pathname.includes('/menu')){
            return <CartSVG />
        }
    }

    navigateBack = () => {
        if(this.pathname.includes('/cart')){
            this.props.history.push('/menu/');
        }else if(this.pathname.includes('/menu')){
            this.props.history.push('/restaurant/');
        }else if(this.pathname.includes('/pendingbill')){
            this.props.history.push('/menu/');
        }
    }

    render(){

        return(
            <nav className="justify-content-between topheader navbar navbar-expand navbar-light">
                <span className="navbar-brand">
                  {this.displayBackButton()}
                </span>

                <span style={{fontSize: 18, fontWeight: 'bold'}}>{this.props.restaurant.name}</span>
                <span>{this.displayCartButton()}</span>
            </nav>
        )
    }
}

function mapStateToProps(state){
    return{
        restaurant : state.restaurant,
        storeTotalBill: user_action.storeTotalBill
    };
}
export default connect(mapStateToProps)(HeaderNavbar);
