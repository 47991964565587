import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {user_input_action} from '../../actions/user_input';

class MenuFilterBar extends Component{

    constructor(props) {
        super(props);
        this.category_list = {...props.category_list};
        this.categoryRows = [];
        this.depth = -1;
        this.selectedCats = [];
        this.selectedCategory = null;
        this.selectedTopCategory = props.tcatid;
    }

    componentDidMount(){
        this.props.setSelectedMenuCat(this.selectedCategory);
    }
    componentDidUpdate(){
        this.props.setSelectedMenuCat(this.selectedCategory);
    }

    onCategoryChange(cat, depth) {
        this.selectedCats[depth] = cat;
        this.categoryRows = [];
        this.depth = -1;
        this.selectedCategory = null;
        this.selectedTopCategory = null;
        this.forceUpdate();
    }

    createCategoryRow(catList, parent){
        let catRow = [];
        let selectedValue = (this.selectedCats[this.depth] && catList.includes(this.selectedCats[this.depth])) ? this.selectedCats[this.depth] : catList[0];
        if(parent === null && this.selectedTopCategory !== null && this.selectedTopCategory !== undefined){
            selectedValue = this.selectedTopCategory;
        }
        let thisItemDepdth = this.depth;
        this.selectedCats[thisItemDepdth] = selectedValue;
        this.selectedCategory = selectedValue;

        for(let id of catList){
            if(id === selectedValue){
                catRow.push(<a href='#' className="selected_menu_category" key={id} onClick={() => this.onCategoryChange(id, thisItemDepdth)}> {this.category_list[id]['title']} </a>);
            }else{
                catRow.push(<a href='#' key={id}  onClick={() => this.onCategoryChange(id, thisItemDepdth)}> {this.category_list[id]['title']} </a>);
            }
        }

        return (
            <div key={"cat_row_"+this.depth}  className="menu_cat_wrapper" >
                {catRow}
            </div>
        );
    }

    checkAndCreateCategoryRows(parent){
        let catsWithParent = [];
        Object.values(this.category_list).forEach(cat => { if(cat.parent == parent && cat.enabled) catsWithParent.push(cat.id)});
        catsWithParent.sort((id_a, id_b) => this.category_list[id_a].display_order - this.category_list[id_b].display_order);
        if(catsWithParent.length > 0){
            this.depth += 1;
            let dropdown = this.createCategoryRow(catsWithParent, parent);
            this.categoryRows.push(dropdown);
            let child = this.selectedCats[this.depth] ? this.selectedCats[this.depth] : catsWithParent[0];
            this.checkAndCreateCategoryRows(child);
        }
    }

    render(){
        this.depth = -1;
        this.categoryRows = [];
        this.checkAndCreateCategoryRows(null);
        return(
            <div id="menufilterbar">
                <div>
                    {this.categoryRows}
                </div>
            </div>
        )
    }

}


function matchDispatchToProps(dispatch){
    return bindActionCreators({
        setSelectedMenuCat: user_input_action.setSelectedMenuCat
    }, dispatch)
}
function mapStateToProps(state){
    return{
        category_list : state.menu.category_list
    };
}
export default connect(mapStateToProps, matchDispatchToProps)(MenuFilterBar);
