import environmentHelper from './helpers/environmentHelper';
import requestHelper from './helpers/requestHelper';
import timeHelper from './helpers/timeHelper';

class Menu_Cls{

    constructor() {
    }

    fetchRestaurantMenu(restaurant_id){
        return new Promise(function(resolve, reject) {
            requestHelper.request({
                url: environmentHelper.getUmsURL() + "/get_item_category_menu/",
                method:'POST',
                body: {restaurant_id, language: 'en'}
            }).then( data => {
                resolve(data);
            }).catch( err => {
                console.warn('errr ' , err);
                reject('Error in fetching restaurant menu');
            });
        });
    }
    


}

export default new Menu_Cls();
