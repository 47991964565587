const userReducer = function(state={authorized: false}, action){

    switch (action.type) {
        case 'STORE_AUTH_STATUS':
            return {...state, authorized: action.payload};
        case 'STORE_USER_PHONE':
            return {...state, phone: action.payload};
        case 'STORE_PENDING_BILL_STATUS':
            return {...state, is_bill_pending: action.payload};
        case 'STORE_ORDER_SESSION_ID':
            return {...state, order_session_id: action.payload};
        case 'STORE_TOTAL_BILL':
            return {...state, total_bill: action.payload};
        default:
            return state;
    }
}

export default userReducer;
