import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from "./reducers/allReducers";
import { composeWithDevTools } from 'redux-devtools-extension';

import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/orange.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n'

const persistConfig = {
  // Root
  key: 'root',
  // Storage Method Browser Storage
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'userInputs',
    'user',
    'menu',
    'cart',
    'restaurant'
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, composeWithDevTools());
let persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={null}>
                <App />
            </Suspense>
        </PersistGate>
    </Provider>,
  document.getElementById('root')
);



//  <React.StrictMode>

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
