import React, { Component } from 'react';
import HeaderNavbar from './components/HeaderNavbar';
import { withTranslation } from 'react-i18next';


class Error extends Component{

    render(){
        let { t } = this.props;
        return(

            <div style={{height:"100vh", backgroundColor:"#f7f2f2"}}>
                <div style={{paddingTop:"10vh"}}><img src={require('../images/error.png')} style={{objectFit:"contain", height:250, width:"100%"}} /></div>
                <div style={{marginTop:"20vh"}}><h5 style={{textAlign:"center",fontSize:30, color: "#2b76ab", fontWeight: "bold"}}>{t('error.oops')}!!!</h5></div>
                <div><h6 style={{textAlign:"center",fontSize:20, color: "#2b76ab", fontWeight: "bold"}}>{t('error.something_went_wrong')}</h6></div>
                <div><h6 style={{textAlign:"center",fontSize:18, color: "#2b76ab", fontWeight: "bold"}}>{t('error.we_encountered_error')}</h6></div>
                <div><h6 style={{textAlign:"center",fontSize:18, color: "#2b76ab", fontWeight: "bold"}}>{t('error.please_try_again')}</h6></div>
            </div>
        )
    }
}



export default withTranslation()(Error);
