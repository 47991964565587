import Hotel from './Hotel';
import requestHelper from './helpers/requestHelper';
import Device from './util/Device';
import environmentHelper from './helpers/environmentHelper';
import Account from './Account';

class Bootstrap{
    constructor() {
        
    }
    
    initialize(urlParam){
        return this.initializeAllModule().then(() => {
            if(urlParam.restaurant_name_url){
                return Hotel.fetchRestaurantAndMenuData(urlParam.restaurant_name_url);
            }else if(urlParam.hotel_name_url && !urlParam.restaurant_name_url){
                return Hotel.fetchHotelAndRestaurantData(urlParam.hotel_id);
            }
        });
    }
    
    initializeAllModule(){
        
        const modules = [requestHelper, Device, environmentHelper, Account];
        
        let prmises = [];
        modules.forEach(function(module){
            if (typeof module.initalize == 'function'){
                 let _mPromise = module.initalize();
                 prmises.push(_mPromise);
            }
        });
        
        return Promise.all(prmises);    
    }
}

export default new Bootstrap();