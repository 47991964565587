const userInputReducer = function(state = {selected_lang: 'en', bookingAllowed: true, instructions : ""}, action ) {

    switch (action.type) {
        case 'SAVE_URL_PARAM':
            return {...state, ...action.payload};
        case 'STORE_DINE_TYPE':
            return {...state, food_service: action.payload};
        case 'SET_SELECTED_MENU_CAT':
            return {...state, selected_menu_category: action.payload};
        case 'SET_ALLOWED_BOOKING_TIME':
            return {...state, ...action.payload};
        case 'SET_LANGUAGE':
            return {...state, selected_lang: action.payload};
        case 'STORE_INSTRUCTIONS':
            return {...state, instructions: action.payload};
        case 'STORE_DELIVERY_ADDRESS':
            return {...state, delivery_address: action.payload};
        default:
            return state;
    }
}


export default userInputReducer;
