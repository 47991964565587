class Common{
    
    getCartItemCount(cart){
        let count = 0;
    
        Object.values(cart).map(item => {
            if(item.custom_quantity){
                Object.values(item.custom_quantity).map(cq => {
                    count += cq.quantity;
                });
            }else{
                count += item.quantity;
            }
        });
        return count;
    }
    
}

export default new Common();