import environmentHelper from './helpers/environmentHelper';
import requestHelper from './helpers/requestHelper';

class FoodOrder_Cls{

    placeOrder(userInputs,restaurant,user,cart){
        let cart_detail = [];

        Object.values(cart).map(item => {
            let itm_obj = {item_id: item.id};
            if(item.custom_quantity){
                itm_obj.custom_quantity = [];
                Object.values(item.custom_quantity).map(cq => {
                    itm_obj.custom_quantity.push({id:cq.id, qty: cq.quantity})
                });
            }else{
                itm_obj.qty = item.quantity;
            }
            cart_detail.push(itm_obj);
        });

        let order_request = {
            "restaurant_id": restaurant.id,
            "food_service": userInputs.food_service,
            "mobile": user.phone,
            "instruction": userInputs.instructions,
            "order_details": cart_detail
        };
        if((userInputs.food_service == 'DINE_IN_WALKIN') && user.order_session_id && user.order_session_id !== null){
            order_request.order_session_id = user.order_session_id;
        }

        if(userInputs.food_service !== 'DINE_IN_WALKIN'){
            order_request.bill_status = { payment_completed: true, payment_mode: "CASH" };
        }
        if(userInputs.food_service === 'DELIVERY'){
            order_request.delivery_address = userInputs.delivery_address;
        }

        return requestHelper.requestWithAuth({
            url: environmentHelper.getUmsURL() + "/place_order/",
            method:'POST',
            body:  order_request
        });
    }
}

export default new FoodOrder_Cls();
