
export default function(state = {}, action){
    

    let _state, id, custom_quantity_id, this_item_count;
    
    switch (action.type) {
        case "ADD_TO_CART":
            ({id, custom_quantity_id} = action.payload);
            _state = {...state};
            
            if(!_state[id]){
                if(custom_quantity_id){
                    _state[id] = {id, custom_quantity: {}};
                    _state[id]['custom_quantity'][custom_quantity_id] = {id:custom_quantity_id, quantity: 1};
                }else{
                    _state[id] = {id, quantity: 1};
                }
            }else{
                if(custom_quantity_id){
                    if(_state[id]['custom_quantity'][custom_quantity_id]){
                        this_item_count = _state[id]['custom_quantity'][custom_quantity_id]['quantity'];
                        _state[id]['custom_quantity'][custom_quantity_id]['quantity'] = this_item_count + 1;
                    }else{
                        _state[id]['custom_quantity'][custom_quantity_id] = {id:custom_quantity_id, quantity: 1};
                    }
                }else{
                    this_item_count = _state[id]['quantity'];
                    _state[id]['quantity'] = this_item_count + 1;
                }
            }

            return _state;
            break;

        case "REMOVE_FROM_CART":
            ({id, custom_quantity_id} = action.payload);
            _state = {...state};
            
            if(custom_quantity_id){
                this_item_count = _state[id]['custom_quantity'][custom_quantity_id]['quantity'];
                if(this_item_count === 1){
                    delete _state[id]['custom_quantity'][custom_quantity_id];
                    
                    if(Object.keys(_state[id]['custom_quantity']).length === 0){
                        delete _state[id];
                    }
                }else{
                    _state[id]['custom_quantity'][custom_quantity_id]['quantity'] = this_item_count - 1;
                }
            }else{
                this_item_count = _state[id]['quantity'];

                if(this_item_count === 1){
                    delete _state[id];
                }else{
                    _state[id]['quantity'] = this_item_count - 1;
                }
            }
            
            return _state;
            break;
        
        case "EMPTY_CART":
            state = {};
            return state;
            break;
        default: 
            return state;
            break;
        
        
    }
}